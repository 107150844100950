import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import './App.css';
import LiveChatWidget from './components/LiveChatWidget/LiveChatWidget';
import LiveChatContextProvider from './contextProviders/LiveChatContextProvider';
function App() {
    return (React.createElement(LiveChatContextProvider, null,
        React.createElement(LiveChatWidget, null)));
}
export default withErrorBoundary(App, {
    FallbackComponent: function () { return React.createElement("div", null); },
    onError: function (error) {
        console.error('Live Chat Error:', error);
    },
});
