var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { v4 as generateUUID } from 'uuid';
import { standardizeFields } from '../../utils/utils';
import * as api from '../../api';
import { useSocketConnection } from './useSocketConnection';
import useLocalStorageBackup from './useLocalStorageBackup';
import { DateTime } from 'luxon';
export function useSessionManager() {
    var _a, _b;
    var backup = useLocalStorageBackup();
    var _c = __read(React.useState((_a = backup.getMessages()) !== null && _a !== void 0 ? _a : []), 2), messages = _c[0], setMessages = _c[1];
    var _d = __read(React.useState(undefined), 2), fields = _d[0], setFields = _d[1];
    var _e = __read(React.useState(undefined), 2), iceBreakers = _e[0], setIceBreakers = _e[1];
    var _f = __read(React.useState(undefined), 2), channelIceBreakers = _f[0], setChannelIceBreakers = _f[1];
    var _g = __read(React.useState({ allow_audio_recording: true }), 2), settings = _g[0], setSettings = _g[1];
    var _h = __read(React.useState((_b = backup.getSession()) !== null && _b !== void 0 ? _b : {
        uuid: generateUUID(),
        state: 'waiting',
        ended_by: null,
        isCompleted: false,
    }), 2), currentSession = _h[0], setCurrentSession = _h[1];
    var _j = __read(React.useState(false), 2), shouldFetchMessages = _j[0], setShouldFetchConversationsMessages = _j[1];
    var _k = __read(React.useState(false), 2), isRetrievingMessages = _k[0], setIsRetrievingMessages = _k[1];
    var shouldFetchRef = React.useRef();
    shouldFetchRef.current = shouldFetchMessages;
    function setShouldFetchMessages(shouldFetch) {
        setShouldFetchConversationsMessages(shouldFetch);
    }
    var _l = __read(React.useState({
        isConnected: false,
        isTryingToConnect: false,
        hasReachedTimeout: false,
    }), 2), realtimeConnection = _l[0], setRealtimeConnection = _l[1];
    var _m = useSocketConnection(realtimeConnection, setRealtimeConnection, performSubscribeToChannels, shouldFetchRef, setShouldFetchMessages, retrieveConversationMessages), subscribeToChannels = _m.subscribeToChannels, unsubscribeFromChannnels = _m.unsubscribeFromChannnels;
    var shouldShowIceBreakers = !!(iceBreakers && !(messages && messages.length > 0));
    function createMessage(message, extraData) {
        if (extraData === void 0) { extraData = {}; }
        var lastMessage = __assign({ message: message, author: 'CONTACT', state: 'pending', when: DateTime.now().toISO(), uuid: generateUUID() }, extraData);
        setMessages(function (previousMessages) {
            var newMessages = __spreadArray(__spreadArray([], __read(previousMessages), false), [lastMessage], false);
            backup.updateMessages(newMessages);
            return newMessages;
        });
        return lastMessage;
    }
    function updateMessage(localMessageUUID, messageData) {
        setMessages(function (previousMessages) {
            var newMessages = previousMessages.map(function (message) {
                return message.uuid === localMessageUUID
                    ? __assign(__assign({}, message), messageData) : message;
            });
            backup.updateMessages(newMessages);
            return newMessages;
        });
    }
    function handleNewMessageFromSocket(newMessage) {
        var _a;
        var messageTags = (_a = newMessage.tags) !== null && _a !== void 0 ? _a : [];
        if (messageTags.includes('internal-message'))
            return;
        setMessages(function (previousMessages) {
            var existingMessageIndex = previousMessages.findIndex(function (message) { return message.uuid === newMessage.uuid; });
            var newMessages = existingMessageIndex !== -1
                ? previousMessages.map(function (message) {
                    return message.uuid === newMessage.uuid ? newMessage : message;
                })
                : __spreadArray(__spreadArray([], __read(previousMessages), false), [newMessage], false);
            backup.updateMessages(newMessages);
            return newMessages;
        });
    }
    function updateSessionFromSocket(session) {
        var sentToSurvey = !!session.survey_sent_at;
        setCurrentSession(function (previousSession) {
            var newState = __assign(__assign({}, previousSession), { state: session.state === 'closed' && sentToSurvey && session.survey_open
                    ? 'survey'
                    : session.state, ended_by: session.ended_by, user: session.user, isCompleted: (session.state === 'closed' && !sentToSurvey) || (sentToSurvey && !session.survey_open) });
            backup.updateSession(newState);
            return newState;
        });
    }
    function updateSession(session) {
        setCurrentSession(function (previousSession) {
            var newState = __assign(__assign({}, previousSession), session);
            backup.updateSession(newState);
            return newState;
        });
    }
    function resetStates(uuid) {
        backup.clearAll();
        setMessages([]);
        unsubscribeFromChannnels();
        setIceBreakers(channelIceBreakers);
        setCurrentSession({
            uuid: uuid,
            state: 'waiting',
            ended_by: null,
            isCompleted: false,
        });
        performSubscribeToChannels(uuid);
    }
    function connectRealtimeConnection() {
        setRealtimeConnection({
            isConnected: true,
            isTryingToConnect: false,
            hasReachedTimeout: false,
        });
    }
    function performSubscribeToChannels(newConversationUUID) {
        subscribeToChannels(newConversationUUID || currentSession.uuid, {
            onNewMessage: handleNewMessageFromSocket,
            onSessionUpdate: updateSessionFromSocket,
            onConnectionSuccessful: connectRealtimeConnection,
        });
    }
    function performUnsubscribeFromChannels() {
        unsubscribeFromChannnels();
    }
    function createNewSession(newSessionInfo, uuid) {
        return __awaiter(this, void 0, void 0, function () {
            var newMessageUUID, newSession, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newMessageUUID = generateUUID();
                        if (newSessionInfo.message) {
                            createMessage(newSessionInfo.message, {
                                uuid: newMessageUUID,
                                state: 'pending',
                            });
                        }
                        newSession = __assign(__assign({}, currentSession), { uuid: uuid || currentSession.uuid });
                        backup.updateSession(newSession);
                        return [4 /*yield*/, api.createLiveChatSession(__assign(__assign({}, newSessionInfo), { conversation_uuid: newSession.uuid, message_uuid: newMessageUUID }))];
                    case 1:
                        response = _a.sent();
                        if (response.data.data.message_uuid) {
                            newSessionInfo.message &&
                                updateMessage(newMessageUUID, {
                                    state: 'sent',
                                });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function createSessionAfterIceBreakers() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var storedFormFields;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        updateIceBreakers(undefined);
                        storedFormFields = (_a = backup.getSession()) === null || _a === void 0 ? void 0 : _a.formFields;
                        if (!storedFormFields) return [3 /*break*/, 2];
                        return [4 /*yield*/, createNewSession(storedFormFields)];
                    case 1:
                        _b.sent();
                        updateSession({ formFields: undefined });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createNewSession({})];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function getChannelData() {
        return __awaiter(this, void 0, void 0, function () {
            var response, settingsData, data, iceBreakersData, fieldsData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.getLivechatChannelData()];
                    case 1:
                        response = _a.sent();
                        settingsData = response.data.data.settings;
                        setSettings(settingsData);
                        data = response.data.data.ice_breakers;
                        iceBreakersData = data.uuid ? data : undefined;
                        setIceBreakers(iceBreakersData);
                        setChannelIceBreakers(iceBreakersData);
                        fieldsData = response.data.data.fields;
                        if (fieldsData) {
                            setFields(standardizeFields(fieldsData));
                            return [2 /*return*/, Promise.resolve()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function retrieveConversationMessages() {
        return __awaiter(this, void 0, void 0, function () {
            var response, messages_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        setIsRetrievingMessages(true);
                        return [4 /*yield*/, api.fetchMessages(currentSession.uuid)];
                    case 1:
                        response = _b.sent();
                        messages_1 = response.data.data;
                        backup.updateMessages(messages_1);
                        setMessages(messages_1);
                        setIsRetrievingMessages(false);
                        return [3 /*break*/, 3];
                    case 2:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function sendMessageToAPI(message) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, messageData, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        updateMessage(message.uuid, { state: 'pending' });
                        return [4 /*yield*/, api.sendNewMessage(message.message, message.uuid, (_a = currentSession.uuid) !== null && _a !== void 0 ? _a : '', message.annex, message.reply_to_uuid)];
                    case 1:
                        response = _c.sent();
                        messageData = response.data.data;
                        updateMessage(message.uuid, __assign(__assign({}, messageData), { when: DateTime.fromHTTP(messageData.when).toISO(), state: 'sent' }));
                        return [3 /*break*/, 3];
                    case 2:
                        _b = _c.sent();
                        updateMessage(message.uuid, { state: 'failed' });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function updateRealtimeConnection(connection) {
        setRealtimeConnection(connection);
    }
    function updateIceBreakers(iceBreakers) {
        setIceBreakers(iceBreakers);
    }
    function setMessagesAsRead() {
        var unreadMessagesUUIDs = messages
            .filter(function (message) { return message.author === 'USER' && message.state !== 'read'; })
            .map(function (message) { return message.uuid; });
        if (unreadMessagesUUIDs.length > 0) {
            api.setMessagesAsRead(currentSession.uuid, unreadMessagesUUIDs);
        }
    }
    return {
        messages: messages,
        fields: fields,
        channelIceBreakers: channelIceBreakers,
        iceBreakers: iceBreakers,
        settings: settings,
        getChannelData: getChannelData,
        currentSession: currentSession,
        createMessage: createMessage,
        resetStates: resetStates,
        sendMessageToAPI: sendMessageToAPI,
        setMessagesAsRead: setMessagesAsRead,
        createNewSession: createNewSession,
        createSessionAfterIceBreakers: createSessionAfterIceBreakers,
        performSubscribeToChannels: performSubscribeToChannels,
        performUnsubscribeFromChannels: performUnsubscribeFromChannels,
        realtimeConnection: realtimeConnection,
        shouldFetchMessages: shouldFetchMessages,
        shouldShowIceBreakers: shouldShowIceBreakers,
        isRetrievingMessages: isRetrievingMessages,
        retrieveConversationMessages: retrieveConversationMessages,
        updateRealtimeConnection: updateRealtimeConnection,
        updateIceBreakers: updateIceBreakers,
        updateSession: updateSession,
    };
}
