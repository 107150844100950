export var parseMessage = function (originalMessage) {
    var matchedUrls = {};
    var parsedMessage = originalMessage.split(/[\s,]+/).reduce(function (acc, word, index, array) {
        if (!matchedUrls[word]) {
            try {
                var url = new URL(word);
                if (!url.host || !url.hostname || !url.origin) {
                    throw new TypeError();
                }
                var escapedLink = word
                    .replace(/\?/g, '\\?')
                    .replace(/\[/g, '\\[')
                    .replace(/\(/g, '\\(')
                    .replace(/\)/g, '\\)')
                    .replace(/\*/g, '\\*')
                    .replace(/\+/g, '\\+')
                    .replace(/\$/g, '\\$')
                    .replace(/\#/g, '\\#');
                if (array.length === 1) {
                    return acc.replace(new RegExp("".concat(escapedLink), 'g'), "[".concat(word, "](").concat(word, ")"));
                }
                else if (index === array.length - 1) {
                    return acc.replace(new RegExp(" ".concat(escapedLink), 'g'), " [".concat(word, "](").concat(word, ")"));
                }
                else {
                    return acc.replace(new RegExp("".concat(escapedLink, " "), 'g'), "[".concat(word, "](").concat(word, ") "));
                }
            }
            catch (e) {
                return acc;
            }
        }
        return acc;
    }, originalMessage);
    return parsedMessage;
};
