var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import Paperclip from '../Icons/Paperclip';
var formatTime = function (time) {
    var truncatedTime = Math.trunc(time);
    var minutes = Math.trunc(truncatedTime / 60)
        .toString()
        .padStart(2, '0');
    var seconds = Math.trunc(truncatedTime % 60)
        .toString()
        .padStart(2, '0');
    return "".concat(minutes, ":").concat(seconds);
};
var Wrapper = styled.article(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 10px 8px;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  background: #f3f3f3;\n  border-radius: 2px;\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n  box-shadow: 0px 0px 0px 1px #e9e9e9;\n  cursor: pointer;\n"], ["\n  display: flex;\n  padding: 10px 8px;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  background: #f3f3f3;\n  border-radius: 2px;\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n  box-shadow: 0px 0px 0px 1px #e9e9e9;\n  cursor: pointer;\n"])));
var LeftSide = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  gap: 4px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  gap: 4px;\n"])));
var AnnexDataWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  svg {\n    color: var(--primary);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  svg {\n    color: var(--primary);\n  }\n"])));
var MessageText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-height: 48px;\n  overflow: hidden;\n"], ["\n  max-height: 48px;\n  overflow: hidden;\n"])));
var MessageInfo = function (_a) {
    var _b, _c, _d, _e, _f;
    var message = _a.message;
    var _g = __read(React.useState(0), 2), audioDuration = _g[0], setAudioDuration = _g[1];
    var isAudioMessage = ((_c = (_b = message.annex) === null || _b === void 0 ? void 0 : _b.content_type) !== null && _c !== void 0 ? _c : '').startsWith('audio');
    var hasAnnex = !!((_d = message.annex) === null || _d === void 0 ? void 0 : _d.uuid);
    React.useEffect(function () {
        var _a, _b, _c;
        var host = document.getElementById('tooke-live-chat-container');
        var audio = (_c = (_a = host === null || host === void 0 ? void 0 : host.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector("source[src=\"".concat((_b = message.annex) === null || _b === void 0 ? void 0 : _b.uri, "\"]"))) === null || _c === void 0 ? void 0 : _c.parentElement;
        if (audio) {
            setAudioDuration(audio.duration);
        }
    }, [(_e = message.annex) === null || _e === void 0 ? void 0 : _e.uri]);
    if (isAudioMessage) {
        return (React.createElement(AnnexDataWrapper, null,
            React.createElement(Icon, { color: "positive", type: "mic", "aria-hidden": true }),
            React.createElement(MessageText, null, formatTime(audioDuration))));
    }
    if (hasAnnex) {
        return (React.createElement(AnnexDataWrapper, null,
            React.createElement(Paperclip, null),
            React.createElement(MessageText, null, (_f = message.annex) === null || _f === void 0 ? void 0 : _f.original_filename)));
    }
    return React.createElement(MessageText, null, message.message);
};
var ReplyPreview = function (_a) {
    var message = _a.message, onCancel = _a.onCancel;
    var i18n = useI18n();
    var handlePreviewClick = function () {
        var host = document.getElementById('tooke-live-chat-container');
        if (host === null || host === void 0 ? void 0 : host.shadowRoot) {
            var targetMessageElement = host.shadowRoot.getElementById(message.uuid);
            if (targetMessageElement) {
                targetMessageElement.scrollIntoView();
            }
        }
    };
    return (React.createElement(Wrapper, { "aria-label": i18n._('Answered message information'), onClick: handlePreviewClick },
        React.createElement(LeftSide, null,
            React.createElement(Text, { color: "positive" }, message.author === 'CONTACT' ? i18n._('You') : i18n._('Agent')),
            React.createElement(MessageInfo, { message: message })),
        onCancel && (React.createElement(Button, { type: "button", "aria-label": i18n._('Cancel answer'), shape: "square", variant: "tertiary", icon: React.createElement(Icon, { type: "cross", "aria-hidden": true }), onClick: onCancel }))));
};
export default ReplyPreview;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
