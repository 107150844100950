var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { TextArea, Button, Spinner, Text } from 'evolux-ui-v2';
import styled from 'styled-components';
import { LiveChatContext, LiveChatMutationsContext, } from '../../contextProviders/LiveChatContextProvider';
import FilesPreviewArea from './FilesPreviewArea';
import AudioRecordingArea from './AudioRecordingArea';
import Actions from './Actions';
import { useI18n } from '../../commons/useI18n';
var ReconnectTextWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
var TextAreaWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n  height: 64px;\n  padding-right: 8px;\n  padding-left: 8px;\n\n  &:has(.ev2-textarea__textarea:disabled) {\n    background: #eee;\n  }\n\n  &:focus-within {\n    box-shadow: 0 0 0 2px var(--primary);\n  }\n\n  .ev2-textarea {\n    width: 90% !important;\n  }\n\n  .ev2-textarea__textarea-wrapper {\n    &:focus-within {\n      box-shadow: none !important;\n    }\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n  height: 64px;\n  padding-right: 8px;\n  padding-left: 8px;\n\n  &:has(.ev2-textarea__textarea:disabled) {\n    background: #eee;\n  }\n\n  &:focus-within {\n    box-shadow: 0 0 0 2px var(--primary);\n  }\n\n  .ev2-textarea {\n    width: 90% !important;\n  }\n\n  .ev2-textarea__textarea-wrapper {\n    &:focus-within {\n      box-shadow: none !important;\n    }\n  }\n"])));
var StyledDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n"], ["\n  align-items: center;\n  display: flex;\n"])));
var StyledTextArea = styled(TextArea)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-grow: 1;\n\n  .ev2-textarea__textarea-wrapper {\n    box-shadow: none;\n    margin: 0;\n  }\n\n  .ev2-textarea__textarea {\n    padding-left: 4px;\n  }\n\n  .ev2-textarea__textarea:disabled {\n    background: initial;\n    box-shadow: none;\n  }\n"], ["\n  flex-grow: 1;\n\n  .ev2-textarea__textarea-wrapper {\n    box-shadow: none;\n    margin: 0;\n  }\n\n  .ev2-textarea__textarea {\n    padding-left: 4px;\n  }\n\n  .ev2-textarea__textarea:disabled {\n    background: initial;\n    box-shadow: none;\n  }\n"])));
var MessageArea = function (_a) {
    var file = _a.file, setFile = _a.setFile, onSendFile = _a.onSendFile, isRecordingAudio = _a.isRecordingAudio, setIsRecordingAudio = _a.setIsRecordingAudio, setErrorMessage = _a.setErrorMessage, onSendAudio = _a.onSendAudio, message = _a.message, setMessage = _a.setMessage, onSubmit = _a.onSubmit, recorder = _a.recorder;
    var i18n = useI18n();
    var _b = React.useContext(LiveChatContext), currentSession = _b.currentSession, realtimeConnection = _b.realtimeConnection;
    var updateRealtimeConnection = React.useContext(LiveChatMutationsContext).updateRealtimeConnection;
    var handleKeyDown = function (event) {
        if (event.key.toLowerCase() === 'enter' && !event.shiftKey) {
            event.persist();
            onSubmit(event);
        }
    };
    var showActions = realtimeConnection.isConnected && !currentSession.isCompleted;
    if (file) {
        return React.createElement(FilesPreviewArea, { onSend: onSendFile, files: [file], onRemove: function () { return setFile(); } });
    }
    if (isRecordingAudio) {
        return (React.createElement(AudioRecordingArea, { recorder: recorder, onSendAudio: onSendAudio, setIsRecordingAudio: setIsRecordingAudio }));
    }
    return (React.createElement(TextAreaWrapper, null,
        React.createElement(StyledTextArea, { id: "message-input", label: "", "aria-label": i18n._('Message'), name: "message", placeholder: i18n._('Type your text here...'), value: message, onChange: function (_, value) { return setMessage(value || ''); }, onKeyDown: handleKeyDown, disabled: !realtimeConnection.isConnected || currentSession.isCompleted, autoFocus: true, required: true }),
        React.createElement(ReconnectTextWrapper, null,
            realtimeConnection.isTryingToConnect && (React.createElement(StyledDiv, null,
                React.createElement(Spinner, { size: 3 }),
                React.createElement(Text, null, i18n._('Trying to reconnect...')))),
            realtimeConnection.hasReachedTimeout && (React.createElement(Button, { type: "button", variant: "primary", color: "positive", onClick: function () {
                    updateRealtimeConnection({
                        isConnected: false,
                        isTryingToConnect: false,
                        hasReachedTimeout: false,
                    });
                } }, i18n._('Try to reconnect')))),
        showActions && (React.createElement(Actions, { setIsRecordingAudio: setIsRecordingAudio, message: message, onSubmit: onSubmit, recorder: recorder, setErrorMessage: setErrorMessage, setFile: setFile }))));
};
export default MessageArea;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
