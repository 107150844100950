var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from 'react';
// https://letsbuildui.dev/articles/how-to-animate-mounting-content-in-react
var useMountTransition = function (isMounted, unmountDelay) {
    var _a = __read(useState(false), 2), hasTransitionedIn = _a[0], setHasTransitionedIn = _a[1];
    useEffect(function () {
        var timeoutId;
        if (isMounted && !hasTransitionedIn) {
            setHasTransitionedIn(true);
        }
        else if (!isMounted && hasTransitionedIn) {
            timeoutId = setTimeout(function () { return setHasTransitionedIn(false); }, unmountDelay);
        }
        return function () {
            clearTimeout(timeoutId);
        };
    }, [unmountDelay, isMounted, hasTransitionedIn]);
    return hasTransitionedIn;
};
export default useMountTransition;
