import cloneDeep from 'lodash/cloneDeep';
export var moveMessageToLastPosition = function (fieldArray) {
    var newFieldArray = cloneDeep(fieldArray || []);
    var messageIndex = newFieldArray.findIndex(function (field) { return field.name === 'message'; });
    if (messageIndex !== -1) {
        var messageField = newFieldArray.splice(messageIndex, 1)[0];
        newFieldArray.push(messageField);
    }
    return newFieldArray;
};
