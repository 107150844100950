import React from 'react';
import { useLiveChatStateManager } from './hooks/useLiveChatStateManager';
import { useSessionManager } from './hooks/useSessionManager';
export var LiveChatContext = React.createContext({
    messages: [],
    fields: undefined,
    settings: {
        allow_audio_recording: true,
    },
    currentSession: { uuid: '', state: 'waiting', ended_by: null, isCompleted: false },
    currentLiveChatState: 'closed',
    iceBreakers: undefined,
    channelIceBreakers: undefined,
    realtimeConnection: {
        isConnected: false,
        isTryingToConnect: false,
        hasReachedTimeout: false,
    },
    shouldFetchMessages: false,
    isRetrievingMessages: false,
    shouldShowIceBreakers: false,
});
export var LiveChatMutationsContext = React.createContext({
    openWidget: function () { },
    closeWidget: function () { },
    createMessage: function () { },
    updateRealtimeConnection: function () { },
    updateIceBreakers: function () { },
    updateSession: function () { },
    getChannelData: function () { return Promise.resolve(); },
    goToCurrentSession: function () { },
    resetWidget: function () { },
    createNewSession: function () { return Promise.resolve(); },
    createSessionAfterIceBreakers: function () { return Promise.resolve(); },
    sendMessageToAPI: function () { return Promise.resolve(); },
    retrieveConversationMessages: function () { return Promise.resolve(); },
});
function LiveChatContextProvider(_a) {
    var children = _a.children;
    var sessionManager = useSessionManager();
    var liveChatStateManager = useLiveChatStateManager();
    function resetWidget(newConversationUuid, fields) {
        sessionManager.resetStates(newConversationUuid);
        liveChatStateManager.resetStates(fields);
    }
    React.useEffect(function () {
        sessionManager.performSubscribeToChannels();
        return function () {
            sessionManager.performUnsubscribeFromChannels();
        };
    }, []);
    return (React.createElement(LiveChatContext.Provider, { value: {
            messages: sessionManager.messages,
            fields: sessionManager.fields,
            settings: sessionManager.settings,
            currentSession: sessionManager.currentSession,
            currentLiveChatState: liveChatStateManager.currentLiveChatState,
            iceBreakers: sessionManager.iceBreakers,
            channelIceBreakers: sessionManager.channelIceBreakers,
            realtimeConnection: sessionManager.realtimeConnection,
            shouldFetchMessages: sessionManager.shouldFetchMessages,
            isRetrievingMessages: sessionManager.isRetrievingMessages,
            shouldShowIceBreakers: sessionManager.shouldShowIceBreakers,
        } },
        React.createElement("div", { onFocus: function () { return sessionManager.setMessagesAsRead(); }, onKeyDown: function () { return sessionManager.setMessagesAsRead(); } },
            React.createElement(LiveChatMutationsContext.Provider, { value: {
                    createMessage: sessionManager.createMessage,
                    getChannelData: sessionManager.getChannelData,
                    createNewSession: sessionManager.createNewSession,
                    createSessionAfterIceBreakers: sessionManager.createSessionAfterIceBreakers,
                    updateRealtimeConnection: sessionManager.updateRealtimeConnection,
                    updateIceBreakers: sessionManager.updateIceBreakers,
                    updateSession: sessionManager.updateSession,
                    sendMessageToAPI: sessionManager.sendMessageToAPI,
                    openWidget: liveChatStateManager.openWidget,
                    closeWidget: liveChatStateManager.closeWidget,
                    goToCurrentSession: liveChatStateManager.goToCurrentSession,
                    retrieveConversationMessages: sessionManager.retrieveConversationMessages,
                    resetWidget: resetWidget,
                } }, children))));
}
export default LiveChatContextProvider;
