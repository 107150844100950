var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../../commons/useI18n';
import Paperclip from '../Icons/Paperclip';
var MB = 1024 * 1024;
var StyledLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    width: 0;\n    height: 0;\n  }\n\n  justify-content: center;\n  align-items: center;\n  font-size: 16px;\n  width: auto;\n  padding: 9px;\n  border-radius: 2px;\n  cursor: ", ";\n  background: ", ";\n  text-transform: uppercase;\n  color: ", ";\n\n  box-shadow: ", ";\n\n  &:hover {\n    background: #e9e9e9;\n  }\n"], ["\n  input {\n    width: 0;\n    height: 0;\n  }\n\n  justify-content: center;\n  align-items: center;\n  font-size: 16px;\n  width: auto;\n  padding: 9px;\n  border-radius: 2px;\n  cursor: ", ";\n  background: ", ";\n  text-transform: uppercase;\n  color: ", ";\n\n  box-shadow: ", ";\n\n  &:hover {\n    background: #e9e9e9;\n  }\n"])), function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return (props.disabled ? '#e9e9e9' : 'none'); }, function (props) { return (props.disabled ? '#ccc' : '#696667'); }, function (props) { return (props.hasFocus ? '0px 0px 0px 2px #0f81e3' : 'none'); });
var FileUploadButton = function (_a) {
    var onChange = _a.onChange, setErrorMessage = _a.setErrorMessage, _b = _a.disabled, disabled = _b === void 0 ? false : _b, isAudioAllowed = _a.isAudioAllowed;
    var i18n = useI18n();
    var _c = __read(React.useState(false), 2), hasFocus = _c[0], setHasFocus = _c[1];
    var handleChange = function (event) {
        if (event.target.files) {
            var file = event.target.files[0];
            if (file.type.startsWith('audio') && !isAudioAllowed) {
                setErrorMessage(i18n._('Audio files are not allowed'));
            }
            else if (file.size >= 25 * MB) {
                setErrorMessage(i18n._('Maximum file size exceeded (25MB)'));
            }
            else {
                onChange(event.target.files[0]);
                setErrorMessage('');
            }
        }
    };
    var className = disabled
        ? 'livechat__file-upload-button--disabled'
        : 'livechat__file-upload-button';
    return (React.createElement(StyledLabel, { "aria-label": i18n._('Upload file'), className: className, disabled: disabled, hasFocus: hasFocus },
        React.createElement(Paperclip, null),
        React.createElement("input", { disabled: disabled, type: "file", value: "", onChange: handleChange, onFocus: function () { return setHasFocus(true); }, onBlur: function () { return setHasFocus(false); } })));
};
export default FileUploadButton;
var templateObject_1;
