var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { Text } from 'evolux-ui-v2';
import { v4 as uuid } from 'uuid';
import { useI18n } from '../../commons/useI18n';
import useRecorder from '../../commons/hooks/useRecorder';
import { LiveChatContext, LiveChatMutationsContext, } from '../../contextProviders/LiveChatContextProvider';
import MessageArea from './MessageArea';
import ReplyPreview from './ReplyPreview';
var Form = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  .ev2-textarea {\n    width: 100%;\n\n    &__textarea {\n      resize: none;\n    }\n  }\n"], ["\n  width: 100%;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  .ev2-textarea {\n    width: 100%;\n\n    &__textarea {\n      resize: none;\n    }\n  }\n"])));
var FormFooter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  margin-top: 8px;\n"])));
function focusTextArea() {
    var textAreaElement = document.querySelector('textarea[name="message"]');
    textAreaElement === null || textAreaElement === void 0 ? void 0 : textAreaElement.focus();
}
function isMessageValid(message) {
    return message.trim() !== '';
}
function CurrentSessionInput(_a) {
    var _this = this;
    var messageBeingReplied = _a.messageBeingReplied, setMessageBeingReplied = _a.setMessageBeingReplied;
    var i18n = useI18n();
    var _b = React.useContext(LiveChatMutationsContext), createMessage = _b.createMessage, sendMessageToAPI = _b.sendMessageToAPI, createSessionAfterIceBreakers = _b.createSessionAfterIceBreakers;
    var shouldShowIceBreakers = React.useContext(LiveChatContext).shouldShowIceBreakers;
    var _c = __read(React.useState(''), 2), message = _c[0], setMessage = _c[1];
    var _d = __read(React.useState(''), 2), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = __read(React.useState(), 2), file = _e[0], setFile = _e[1];
    var _f = __read(React.useState(false), 2), isInRecordingMode = _f[0], setIsInRecordingMode = _f[1];
    var recorder = useRecorder();
    var sendMessage = function (extraData) { return __awaiter(_this, void 0, void 0, function () {
        var newMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isMessageValid(message) || file || recorder.recordedData)) return [3 /*break*/, 3];
                    newMessage = createMessage(message, __assign(__assign({}, extraData), (messageBeingReplied ? { reply_to_uuid: messageBeingReplied.uuid } : {})));
                    if (!shouldShowIceBreakers) return [3 /*break*/, 2];
                    return [4 /*yield*/, createSessionAfterIceBreakers()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (newMessage) {
                        sendMessageToAPI(newMessage);
                    }
                    setMessage('');
                    setMessageBeingReplied(undefined);
                    focusTextArea();
                    setErrorMessage('');
                    setFile(null);
                    recorder.discardRecordedData();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var sendFile = function (file) {
        var annex = {
            file: file,
            content_type: file.type,
            original_filename: file.name,
            uri: URL.createObjectURL(file),
            uuid: uuid(),
        };
        sendMessage({ annex: annex });
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        sendMessage();
    };
    var handleSendFile = function () {
        if (file) {
            sendFile(file);
        }
    };
    var handleSendAudio = function () { return __awaiter(_this, void 0, void 0, function () {
        var audioFile;
        return __generator(this, function (_a) {
            if (recorder.recordedData) {
                audioFile = new File([recorder.recordedData], 'user-audio.mpeg', {
                    type: 'audio/mpeg',
                });
                sendFile(audioFile);
                setIsInRecordingMode(false);
            }
            return [2 /*return*/];
        });
    }); };
    var handleCancelReply = function () {
        setMessageBeingReplied(undefined);
    };
    return (React.createElement(Form, { className: "current-session__input-wrapper", onSubmit: handleSubmit, "aria-label": i18n._('Input area'), autoComplete: "off" },
        messageBeingReplied && (React.createElement(ReplyPreview, { message: messageBeingReplied, onCancel: handleCancelReply })),
        React.createElement(MessageArea, { file: file, setFile: setFile, onSendFile: handleSendFile, isRecordingAudio: isInRecordingMode, setIsRecordingAudio: setIsInRecordingMode, onSendAudio: handleSendAudio, setErrorMessage: setErrorMessage, message: message, setMessage: setMessage, onSubmit: handleSubmit, recorder: recorder }),
        React.createElement(FormFooter, null,
            React.createElement("span", { role: "alert" }, errorMessage && (React.createElement(Text, { size: "sm", color: "red" }, errorMessage))),
            React.createElement(Text, { size: "sm", transparent: true }, "Powered by Evolux CX"))));
}
export default CurrentSessionInput;
var templateObject_1, templateObject_2;
