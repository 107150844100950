import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
// @ts-ignore
import globalStyleCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./GlobalStyle.css';
// @ts-ignore
import evoluxUiCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!evolux-ui-v2/dist/main.css';
// @ts-ignore
import markdownCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./components/MessageComponent/MessageContent/markdownStyles.css';
// @ts-ignore
import phoneNumberInputCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!react-phone-number-input/style.css';
import App from './App';
import { StyleSheetManager } from 'styled-components';
var SECOND = 1000;
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
        Sentry.replayIntegration({
            maxReplayDuration: 60 * SECOND,
            networkDetailAllowUrls: [window.location.origin],
        }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.2,
    initialScope: function (scope) {
        scope.setTag('PUBLIC_URL', window.TOOKE_API_PUBLIC_URL || process.env.TOOKE_API_PUBLIC_URL);
        return scope;
    },
});
var shadowHost = document.createElement('div');
shadowHost.id = 'tooke-live-chat-container';
var shadowRoot = shadowHost.attachShadow({ mode: 'open' });
var styledComponentsStyleSlot = document.createElement('section');
var globalStyle = document.createElement('style');
var evoluxUIStyle = document.createElement('style');
var markdownStyle = document.createElement('style');
var phoneNumberInputStyle = document.createElement('style');
evoluxUIStyle.textContent = evoluxUiCss;
markdownStyle.textContent = markdownCss;
phoneNumberInputStyle.textContent = phoneNumberInputCss;
globalStyle.textContent = globalStyleCss;
shadowRoot.appendChild(styledComponentsStyleSlot);
shadowRoot.appendChild(evoluxUIStyle);
shadowRoot.appendChild(markdownStyle);
shadowRoot.appendChild(phoneNumberInputStyle);
shadowRoot.appendChild(globalStyle);
document.body.appendChild(shadowHost);
var root = createRoot(shadowRoot);
root.render(React.createElement(React.StrictMode, null,
    React.createElement(StyleSheetManager, { target: styledComponentsStyleSlot },
        React.createElement(App, null))));
