var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding: 0 8px;\n  margin: 0;\n  gap: 10px;\n  height: 64px;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding: 0 8px;\n  margin: 0;\n  gap: 10px;\n  height: 64px;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n"])));
var TimerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n\n  .ev2-text {\n    margin: 0;\n    font-size: 24px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n\n  .ev2-text {\n    margin: 0;\n    font-size: 24px;\n  }\n"])));
var formatTimePart = function (timePart) { return String(timePart).padStart(2, '0'); };
var parseTime = function (time) {
    var minutes = Math.floor(time / 60);
    var seconds = time % 60;
    return "".concat(formatTimePart(minutes), ":").concat(formatTimePart(seconds));
};
var AudioRecordingArea = function (_a) {
    var recorder = _a.recorder, onSendAudio = _a.onSendAudio, setIsRecordingAudio = _a.setIsRecordingAudio;
    var i18n = useI18n();
    var handleCancelRecording = function () {
        recorder.stopRecording().then(function () {
            recorder.discardRecordedData();
            setIsRecordingAudio(false);
        });
    };
    React.useEffect(function () {
        if (recorder.recordedData) {
            onSendAudio();
        }
    }, [recorder.recordedData]);
    return (React.createElement(Wrapper, null,
        React.createElement(Button, { "aria-label": i18n._('Discard audio'), type: "button", shape: "square", variant: "tertiary", onClick: handleCancelRecording },
            React.createElement(Icon, { type: "trash", color: "negative", "aria-hidden": true })),
        React.createElement(TimerWrapper, null,
            React.createElement(Text, { size: "lg", bold: true, transparent: true }, parseTime(recorder.recordingTime))),
        React.createElement(Button, { "aria-label": i18n._('Send audio'), type: "button", shape: "square", color: "positive", variant: "tertiary", icon: React.createElement(Icon, { type: "arrow-send", "aria-hidden": true }), onClick: recorder.stopRecording })));
};
export default AudioRecordingArea;
var templateObject_1, templateObject_2;
