var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Icon, Button } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import FilePreview from './FilePreview';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: space-between;\n  align-content: center;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 0 8px;\n  margin: 0;\n  height: 64px;\n  gap: 20px;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n"], ["\n  justify-content: space-between;\n  align-content: center;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 0 8px;\n  margin: 0;\n  height: 64px;\n  gap: 20px;\n  box-shadow: 0px 0px 0px 1px #999;\n  border-radius: 2px;\n"])));
var FilesPreviewArea = function (_a) {
    var files = _a.files, onRemove = _a.onRemove, onSend = _a.onSend;
    var i18n = useI18n();
    return (React.createElement(Wrapper, null,
        files.map(function (file, index) { return (React.createElement(FilePreview, { key: file.name, file: file, onRemove: function () { return onRemove(index); } })); }),
        React.createElement(Button, { "aria-label": i18n._('Send file'), type: "button", shape: "square", color: "positive", variant: "tertiary", icon: React.createElement(Icon, { type: "arrow-send", "aria-hidden": true }), onClick: onSend })));
};
export default FilesPreviewArea;
var templateObject_1;
