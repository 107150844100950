var SESSION_KEY = 'livechat-session';
var MESSAGES_KEY = 'livechat-messages';
var useLocalStorageBackup = function () {
    var getSession = function () {
        var session = localStorage.getItem(SESSION_KEY);
        if (session) {
            return JSON.parse(session);
        }
        return null;
    };
    var updateSession = function (newState) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(newState));
    };
    var clearSession = function () {
        localStorage.removeItem(SESSION_KEY);
    };
    var getMessages = function () {
        var messages = localStorage.getItem(MESSAGES_KEY);
        if (messages) {
            return JSON.parse(messages);
        }
        return null;
    };
    var updateMessages = function (newMessages) {
        localStorage.setItem(MESSAGES_KEY, JSON.stringify(newMessages));
    };
    var clearMessages = function () {
        localStorage.removeItem(MESSAGES_KEY);
    };
    var clearAll = function () {
        clearSession();
        clearMessages();
    };
    return {
        getSession: getSession,
        updateSession: updateSession,
        clearSession: clearSession,
        getMessages: getMessages,
        updateMessages: updateMessages,
        clearMessages: clearMessages,
        clearAll: clearAll,
    };
};
export default useLocalStorageBackup;
