// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/ev2-icons.eot?migch1", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/ev2-icons.ttf?migch1", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/ev2-icons.woff?migch1", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/ev2-icons.svg?migch1", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,\nbutton,\nhtml,\ninput,\nselect,\ntextarea {\n  font-family: Open Sans, sans-serif;\n}\n\n@font-face {\n  font-family: 'ev2-icons';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n#tooke-live-chat-container {\n  display: flex;\n  position: fixed;\n  right: 20px;\n  bottom: 20px;\n  z-index: 999999999;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;EAME,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;EACxB,4CAAyC;EACzC;;;yDAG4D;EAC5D,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["body,\nbutton,\nhtml,\ninput,\nselect,\ntextarea {\n  font-family: Open Sans, sans-serif;\n}\n\n@font-face {\n  font-family: 'ev2-icons';\n  src: url('../fonts/ev2-icons.eot?migch1');\n  src: url('../fonts/ev2-icons.eot?migch1#iefix') format('embedded-opentype'),\n    url('../fonts/ev2-icons.ttf?migch1') format('truetype'),\n    url('../fonts/ev2-icons.woff?migch1') format('woff'),\n    url('../fonts/ev2-icons.svg?migch1#icomoon') format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n#tooke-live-chat-container {\n  display: flex;\n  position: fixed;\n  right: 20px;\n  bottom: 20px;\n  z-index: 999999999;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
