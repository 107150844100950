var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Text, Spinner, Icon } from 'evolux-ui-v2';
import React, { useState } from 'react';
import { useI18n } from '../../../commons/useI18n';
import { downloadFile } from '../../../utils/utils';
import styled from 'styled-components';
var i18n = useI18n();
var StyledSpinner = styled(Spinner)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 3px !important;\n"], ["\n  margin-right: 3px !important;\n"])));
var StyledIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  align-items: flex-start;\n"])));
var DownloadingText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  color: gray;\n"], ["\n  font-size: 14px;\n  color: gray;\n"])));
function AudioPreviewer(_a) {
    var annex = _a.annex;
    var audioType = annex === null || annex === void 0 ? void 0 : annex.content_type.toLowerCase();
    return (React.createElement("audio", { controls: true, "aria-label": i18n._('Audio previewer') },
        React.createElement("source", { src: annex === null || annex === void 0 ? void 0 : annex.uri, type: audioType }),
        React.createElement(Text, { transparent: true, size: "sm" }, i18n._('The audio player is not supported on your browser.')),
        React.createElement(Text, { type: "link", href: annex === null || annex === void 0 ? void 0 : annex.uri, target: "_blank" }, i18n._('Click here to download the audio.'))));
}
function GenericPreviewer(_a) {
    var annex = _a.annex;
    var _b = __read(useState(false), 2), isDownloading = _b[0], setIsDownloading = _b[1];
    var onClick = function () {
        setIsDownloading(true);
        annex &&
            downloadFile(annex.uri, annex.original_filename)
                .catch(console.error)
                .finally(function () { return setIsDownloading(false); });
    };
    return (React.createElement(React.Fragment, null, isDownloading ? (React.createElement(Wrapper, null,
        React.createElement(StyledSpinner, { size: 3 }),
        React.createElement(DownloadingText, null,
            " ", annex === null || annex === void 0 ? void 0 :
            annex.original_filename,
            " "))) : (React.createElement(Text, { type: "link", "aria-description": i18n._('Sent an annex, click in the file name to download it.'), href: "#", onClick: onClick },
        React.createElement(StyledIcon, { type: "download", "aria-hidden": true }), annex === null || annex === void 0 ? void 0 :
        annex.original_filename))));
}
function getAnnexPreviewerComponentFromContentType(contentType) {
    var mediaType = contentType.toLowerCase().split('/')[0];
    switch (mediaType) {
        case 'audio':
            return AudioPreviewer;
        default:
            return GenericPreviewer;
    }
}
function AnnexContent(_a) {
    var _b;
    var message = _a.message;
    var AnnexPreviewerComponent = getAnnexPreviewerComponentFromContentType(((_b = message.annex) === null || _b === void 0 ? void 0 : _b.content_type) || '');
    return React.createElement(AnnexPreviewerComponent, { annex: message.annex });
}
export default AnnexContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
