var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'evolux-ui-v2';
import TextInnerContent from './TextContent';
import { LiveChatContext, LiveChatMutationsContext, } from '../../../contextProviders/LiveChatContextProvider';
var BUTTON_VARIANT = 
// @ts-ignore
window.LIVECHAT_INTERACTIVE_BUTTON_VARIANT === 'primary' ? 'primary' : 'secondary';
var MessageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var TextWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 16px 16px 16px 2px;\n  background-color: #f3f3f3;\n  padding: 8px;\n"], ["\n  border-radius: 16px 16px 16px 2px;\n  background-color: #f3f3f3;\n  padding: 8px;\n"])));
var ButtonsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"])));
var StyledButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 16px 16px 16px 2px !important;\n  height: auto !important;\n"], ["\n  border-radius: 16px 16px 16px 2px !important;\n  height: auto !important;\n"])));
var InteractiveButtonsContent = function (_a) {
    var _b, _c;
    var message = _a.message;
    var _d = React.useContext(LiveChatMutationsContext), createMessage = _d.createMessage, createSessionAfterIceBreakers = _d.createSessionAfterIceBreakers, sendMessageToAPI = _d.sendMessageToAPI;
    var _e = React.useContext(LiveChatContext), currentSession = _e.currentSession, realtimeConnection = _e.realtimeConnection, shouldShowIceBreakers = _e.shouldShowIceBreakers;
    var _f = __read(React.useState(false), 2), wasPressed = _f[0], setWasPressed = _f[1];
    var isIceBreakersMessage = message.interactive_content.interactive.type === 'iceBreakers';
    var handleClick = function (title) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var newMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isIceBreakersMessage) return [3 /*break*/, 2];
                    return [4 /*yield*/, createSessionAfterIceBreakers()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    newMessage = createMessage(title);
                    sendMessageToAPI(newMessage).then(function () { return setWasPressed(true); });
                    return [2 /*return*/];
            }
        });
    }); }; };
    var buttons = (_c = (_b = message.interactive_content) === null || _b === void 0 ? void 0 : _b.interactive.action.buttons) !== null && _c !== void 0 ? _c : [];
    var wasIceBreakersPressed = isIceBreakersMessage && !shouldShowIceBreakers;
    var disabled = !realtimeConnection.isConnected ||
        currentSession.isCompleted ||
        wasPressed ||
        wasIceBreakersPressed;
    return (React.createElement(MessageWrapper, null,
        React.createElement(TextWrapper, null,
            React.createElement(TextInnerContent, { message: message })),
        React.createElement(ButtonsWrapper, null, buttons.map(function (button) { return (React.createElement(StyledButton, { variant: BUTTON_VARIANT, color: "positive", key: button.reply.id, onClick: handleClick(button.reply.title), disabled: disabled }, button.reply.title)); }))));
};
export default InteractiveButtonsContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
