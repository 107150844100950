var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Icon } from 'evolux-ui-v2';
import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../../commons/useI18n';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
// @ts-ignore
var backgroundURL = window.LIVECHAT_BACKGROUND_URL;
// @ts-ignore
var backgroundMessagesOnly = window.LIVECHAT_BACKGROUND_MESSAGES_ONLY;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  background-color: #fdfdfd;\n  border: 1px solid #999;\n  border-radius: 10px;\n  width: 400px;\n  padding: 8px 16px 16px 16px;\n  ", "\n\n  @media (max-width: 500px) {\n    width: 300px;\n  }\n\n  @media (max-width: 400px) {\n    width: 250px;\n  }\n"], ["\n  position: relative;\n  overflow: hidden;\n  background-color: #fdfdfd;\n  border: 1px solid #999;\n  border-radius: 10px;\n  width: 400px;\n  padding: 8px 16px 16px 16px;\n  ", "\n\n  @media (max-width: 500px) {\n    width: 300px;\n  }\n\n  @media (max-width: 400px) {\n    width: 250px;\n  }\n"])), !backgroundMessagesOnly &&
    "\n  background: no-repeat url(".concat(backgroundURL, ");\n  background-size: cover;\n  "));
function WidgetContainer(_a) {
    var onClose = _a.onClose, children = _a.children;
    var i18n = useI18n();
    return (React.createElement(Wrapper, null,
        React.createElement(WidgetHeader, { actions: React.createElement(Button, { onClick: onClose, "aria-label": i18n._('Close tooke live chat'), title: i18n._('Close tooke live chat'), shape: "circle", size: "md", variant: "tertiary", icon: React.createElement(Icon, { type: "arrow-down", size: "sm" }) }) }),
        React.createElement("main", null, children)));
}
export default WidgetContainer;
var templateObject_1;
