var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import NewSessionForm from '../NewSessionForm/NewSessionForm';
import CurrentSession from '../CurrentSession/CurrentSession';
import OpenWidgetButton from '../OpenWidgetButton/OpenWidgetButton';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import { LiveChatContext, LiveChatMutationsContext, } from '../../contextProviders/LiveChatContextProvider';
function LiveChatWidget() {
    var _a = __read(React.useState(false), 2), isWidgetReady = _a[0], setIsWidgetReady = _a[1];
    var _b = React.useContext(LiveChatContext), currentLiveChatState = _b.currentLiveChatState, fields = _b.fields, shouldShowIceBreakers = _b.shouldShowIceBreakers, settings = _b.settings;
    var _c = React.useContext(LiveChatMutationsContext), closeWidget = _c.closeWidget, createNewSession = _c.createNewSession, openWidget = _c.openWidget, goToCurrentSession = _c.goToCurrentSession, getChannelData = _c.getChannelData;
    var handleOpenWidget = function () {
        return openWidget(!!(fields === null || fields === void 0 ? void 0 : fields.length), createNewSession, shouldShowIceBreakers);
    };
    useEffect(function () {
        getChannelData()
            .then(function () {
            setIsWidgetReady(true);
        })
            .catch(console.error);
    }, []);
    useEffect(function () {
        if (typeof settings.proactive_chat_time === 'number') {
            setTimeout(handleOpenWidget, settings.proactive_chat_time * 1000);
        }
    }, [settings.proactive_chat_time]);
    switch (currentLiveChatState) {
        case 'closed':
            return React.createElement(OpenWidgetButton, { onOpen: handleOpenWidget, isLoading: !isWidgetReady });
        case 'creating session':
            return (React.createElement(WidgetContainer, { onClose: closeWidget },
                React.createElement(NewSessionForm, { fields: fields, onCreationSuccess: goToCurrentSession })));
        case 'messaging':
            return (React.createElement(WidgetContainer, { onClose: closeWidget },
                React.createElement(CurrentSession, null)));
    }
}
export default LiveChatWidget;
