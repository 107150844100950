var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Spinner } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import ChatIcon from '../../svgIcons/chat.svg';
import UnreadMessageIcon from '../../svgIcons/unread-messages.svg';
import { LiveChatContext } from '../../contextProviders/LiveChatContextProvider';
//@ts-ignore
var IMG_URL = window.LIVECHAT_AVATAR_ICON;
//@ts-ignore
var IMG_WIDTH = window.LIVECHAT_AVATAR_ICON_WIDTH;
//@ts-ignore
var IMG_HEIGHT = window.LIVECHAT_AVATAR_ICON_HEIGHT;
var StyledSpinner = styled(Spinner)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 0 !important;\n"], ["\n  margin-right: 0 !important;\n"])));
var OpenWidgetContainerElement = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n\n  .ev2-button {\n    border-radius: 50%;\n    min-height: 64px;\n    min-width: 64px;\n    height: unset;\n  }\n\n  .new-messages-icon {\n    display: inline-block;\n    position: absolute;\n    top: 0;\n    right: 5px;\n\n    &__description {\n      display: none;\n    }\n  }\n"], ["\n  position: relative;\n\n  .ev2-button {\n    border-radius: 50%;\n    min-height: 64px;\n    min-width: 64px;\n    height: unset;\n  }\n\n  .new-messages-icon {\n    display: inline-block;\n    position: absolute;\n    top: 0;\n    right: 5px;\n\n    &__description {\n      display: none;\n    }\n  }\n"])));
function OpenWidgetButton(_a) {
    var onOpen = _a.onOpen, isLoading = _a.isLoading;
    var i18n = useI18n();
    var messages = React.useContext(LiveChatContext).messages;
    var previousMessageCount = React.useRef(messages.length);
    var thereAreNewMessages = previousMessageCount.current < messages.length;
    React.useEffect(function () {
        if (messages.length !== previousMessageCount.current) {
            previousMessageCount.current = messages.length;
        }
    }, [messages.length]);
    if (isLoading) {
        return (React.createElement(OpenWidgetContainerElement, { className: "livechat__avatar" },
            React.createElement(Button, { disabled: true, "aria-label": i18n._('Loading tooke live chat') },
                React.createElement(StyledSpinner, { size: 6 }))));
    }
    return (React.createElement(OpenWidgetContainerElement, { className: "livechat__avatar" },
        React.createElement(Button, { onClick: onOpen, "aria-label": i18n._('Open tooke live chat') }, IMG_URL ? (React.createElement("img", { alt: "", src: IMG_URL, width: IMG_WIDTH !== null && IMG_WIDTH !== void 0 ? IMG_WIDTH : 28, height: IMG_HEIGHT !== null && IMG_HEIGHT !== void 0 ? IMG_HEIGHT : 28, "aria-hidden": true })) : (React.createElement(ChatIcon, { width: IMG_WIDTH !== null && IMG_WIDTH !== void 0 ? IMG_WIDTH : 28, height: IMG_HEIGHT !== null && IMG_HEIGHT !== void 0 ? IMG_HEIGHT : 28, "aria-hidden": true }))),
        thereAreNewMessages && (React.createElement("span", { "aria-label": i18n._('New messages icon'), "aria-describedby": "new-messages-icon-description", className: "new-messages-icon", title: i18n._('There are new unread messages, click on the button to open them') },
            React.createElement(UnreadMessageIcon, { width: 14, height: 14, "aria-hidden": true }),
            React.createElement("span", { className: "new-messages-icon__description", id: "new-messages-icon-description" }, i18n._('There are new unread messages, click on the button to open them'))))));
}
export default OpenWidgetButton;
var templateObject_1, templateObject_2;
