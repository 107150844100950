var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import FileUploadButton from './FileUploadButton';
import { LiveChatContext } from '../../contextProviders/LiveChatContextProvider';
var SendOrAudioButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 15px 10px;\n"], ["\n  padding: 15px 10px;\n"])));
var Actions = function (_a) {
    var message = _a.message, onSubmit = _a.onSubmit, recorder = _a.recorder, setIsRecordingAudio = _a.setIsRecordingAudio, setErrorMessage = _a.setErrorMessage, setFile = _a.setFile;
    var i18n = useI18n();
    var settings = React.useContext(LiveChatContext).settings;
    var handleClick = function (event) {
        if (message) {
            return onSubmit(event);
        }
        setIsRecordingAudio(true);
        recorder.startRecording().catch(function (error) {
            console.error(error);
            setErrorMessage(i18n._('Permission not granted'));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FileUploadButton, { onChange: setFile, setErrorMessage: setErrorMessage, isAudioAllowed: settings.allow_audio_recording }),
        settings.allow_audio_recording ? (React.createElement(SendOrAudioButton, { onClick: handleClick, "aria-label": message ? i18n._('Send message') : i18n._('Record audio'), color: message ? 'positive' : 'neutral', variant: "tertiary", shape: "square", type: "button", icon: React.createElement(Icon, { type: message ? 'arrow-send' : 'mic', "aria-hidden": true }) })) : (message && (React.createElement(SendOrAudioButton, { onClick: handleClick, "aria-label": i18n._('Send message'), color: 'positive', variant: "tertiary", shape: "square", type: "button", icon: React.createElement(Icon, { type: 'arrow-send', "aria-hidden": true }) })))));
};
export default Actions;
var templateObject_1;
