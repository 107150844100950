var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Text, Hint } from 'evolux-ui-v2';
import { useIMask } from 'react-imask';
import styled from 'styled-components';
var CPF_MASK = '000.000.000-00';
var CNPJ_MASK = '00.000.000/0000-00';
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  padding: 8px 12px;\n  margin: 8px 0 4px;\n  border-radius: 2px;\n  box-shadow: 0 0 0 ", ";\n\n  &:focus {\n    outline: none;\n    box-shadow: 0 0 0 2px var(--primary);\n  }\n"], ["\n  border: none;\n  padding: 8px 12px;\n  margin: 8px 0 4px;\n  border-radius: 2px;\n  box-shadow: 0 0 0 ", ";\n\n  &:focus {\n    outline: none;\n    box-shadow: 0 0 0 2px var(--primary);\n  }\n"])), function (_a) {
    var hasErrors = _a.hasErrors;
    return (hasErrors ? "2px var(--negative)" : '1px #999');
});
var StyledHint = styled(Hint)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & > span {\n    font-size: 12px !important;\n  }\n"], ["\n  & > span {\n    font-size: 12px !important;\n  }\n"])));
var CPFCNPJInput = function (_a) {
    var onChange = _a.onChange, label = _a.label, errorHint = _a.errorHint, props = __rest(_a, ["onChange", "label", "errorHint"]);
    var mask = useIMask({ mask: [CPF_MASK, CNPJ_MASK] }, {
        onAccept: function (_, mask) { return onChange(mask.unmaskedValue); },
    });
    return (React.createElement(Label, null,
        React.createElement(Text, { size: "sm", bold: true }, label),
        React.createElement(Input, __assign({}, props, { ref: mask.ref, hasErrors: !!errorHint })),
        errorHint && React.createElement(StyledHint, { text: errorHint, color: "negative" })));
};
export default CPFCNPJInput;
var templateObject_1, templateObject_2, templateObject_3;
