var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Radio, Text } from 'evolux-ui-v2';
import TextInnerContent from './TextContent';
import { LiveChatContext, LiveChatMutationsContext, } from '../../../contextProviders/LiveChatContextProvider';
import { useFormik } from 'formik';
import { useI18n } from '../../../commons/useI18n';
import useMountTransition from '../../../contextProviders/hooks/useMountTransition';
var BUTTON_VARIANT = 
// @ts-ignore
window.LIVECHAT_INTERACTIVE_BUTTON_VARIANT === 'primary' ? 'primary' : 'secondary';
var CustomRadioWrapper = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 44px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  gap: 5px;\n\n  &:hover .ev2-radio__span {\n    background-color: var(--primaryLight);\n    border-color: var(--primary);\n    box-shadow: 0 0 1px 6px var(--primaryLight);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 44px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  gap: 5px;\n\n  &:hover .ev2-radio__span {\n    background-color: var(--primaryLight);\n    border-color: var(--primary);\n    box-shadow: 0 0 1px 6px var(--primaryLight);\n  }\n"])));
var CustomRadioLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n"])));
var CustomRadio = function (_a) {
    var children = _a.children, label = _a.label, rest = __rest(_a, ["children", "label"]);
    return (React.createElement(CustomRadioWrapper, null,
        React.createElement(CustomRadioLabel, null,
            React.createElement(Text, null, label),
            React.createElement(Radio, __assign({}, rest))),
        children));
};
var ItemsListContainer = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  z-index: 2;\n  position: absolute;\n  overflow: hidden;\n  left: 0;\n  bottom: 0;\n  border-radius: 10px;\n  box-sizing: border-box;\n  box-shadow: 0px -6px 18px #00000029;\n  padding: 10px;\n  width: 100%;\n  height: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: white;\n  transition: height 250ms;\n"], ["\n  z-index: 2;\n  position: absolute;\n  overflow: hidden;\n  left: 0;\n  bottom: 0;\n  border-radius: 10px;\n  box-sizing: border-box;\n  box-shadow: 0px -6px 18px #00000029;\n  padding: 10px;\n  width: 100%;\n  height: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: white;\n  transition: height 250ms;\n"])), function (_a) {
    var shouldBeVisible = _a.shouldBeVisible;
    return (shouldBeVisible ? '75%' : '1%');
});
var ItemsListTitleHeader = styled.header(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  justify-content: center;\n"], ["\n  display: flex;\n  position: relative;\n  justify-content: center;\n"])));
var CloseButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: 3px;\n"], ["\n  position: absolute;\n  right: 0;\n  top: 3px;\n"])));
var ItemsListBody = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  padding-left: 10px;\n  padding-right: 10px;\n  margin-bottom: 20px;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #999999;\n    border-radius: 8px;\n    width: 16px;\n    border: 4px solid white;\n  }\n\n  &::-webkit-scrollbar-track {\n    opacity: 0;\n  }\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  padding-left: 10px;\n  padding-right: 10px;\n  margin-bottom: 20px;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #999999;\n    border-radius: 8px;\n    width: 16px;\n    border: 4px solid white;\n  }\n\n  &::-webkit-scrollbar-track {\n    opacity: 0;\n  }\n"])));
var SectionWrapper = styled.fieldset(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border: none;\n  padding: 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  border: none;\n  padding: 0;\n"])));
var RadioButtonsWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n"])));
var ItemsListDialog = function (_a) {
    var _b, _c, _d;
    var hasTransitionedIn = _a.hasTransitionedIn, message = _a.message, onSend = _a.onSend, close = _a.close;
    var sections = message.interactive_content.interactive.action.sections;
    var i18n = useI18n();
    var _e = __read(React.useState(false), 2), isClosing = _e[0], setIsClosing = _e[1];
    var handleClose = function () {
        setIsClosing(true);
        close();
    };
    var formik = useFormik({
        initialValues: {
            listItem: (_d = (_c = (_b = sections[0]) === null || _b === void 0 ? void 0 : _b.rows[0]) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : '',
        },
        onSubmit: function (values) {
            onSend(values.listItem);
            handleClose();
        },
        enableReinitialize: true,
    });
    var handleBlur = function (event) {
        var currentTarget = event.currentTarget;
        requestAnimationFrame(function () {
            var host = document.getElementById('tooke-live-chat-container');
            if ((host === null || host === void 0 ? void 0 : host.shadowRoot) && !currentTarget.contains(host.shadowRoot.activeElement)) {
                handleClose();
            }
        });
    };
    var handleKeyDown = function (event) {
        switch (event.key) {
            case 'Escape':
                handleClose();
                break;
            default:
        }
    };
    return (React.createElement("div", { role: "dialog", "aria-modal": "true", "aria-labelledby": "".concat(message.uuid, "-title"), onBlur: handleBlur, onKeyDown: handleKeyDown, tabIndex: 0 },
        React.createElement(ItemsListContainer, { onSubmit: formik.handleSubmit, shouldBeVisible: hasTransitionedIn && !isClosing },
            React.createElement(ItemsListTitleHeader, null,
                React.createElement(Text, { id: "".concat(message.uuid, "-title"), type: "title" }, message.interactive_content.interactive.action.button),
                React.createElement(CloseButton, { type: "button", "aria-label": i18n._('Close'), variant: "tertiary", shape: "circle", icon: React.createElement(Icon, { type: "cross", "aria-hidden": true }), onClick: handleClose })),
            React.createElement(ItemsListBody, { id: "scrollable-body" }, sections.map(function (section, sectionIndex) { return (React.createElement(SectionWrapper, { key: sectionIndex },
                section.title && (React.createElement("legend", null,
                    React.createElement(Text, { type: "title", size: "sm" }, section.title))),
                React.createElement(RadioButtonsWrapper, null, section.rows.map(function (row, optionIndex) { return (React.createElement(CustomRadio, { id: row.id, name: "listItem", key: row.id, label: row.title, value: row.title, checked: formik.values.listItem === row.title, onChange: formik.handleChange, labelPosition: "left", autoFocus: sectionIndex === 0 && optionIndex === 0 }, row.description && (React.createElement(Text, { size: "sm", transparent: true }, row.description)))); })))); })),
            React.createElement(Button, { type: "submit", color: "positive" }, i18n._('Send')))));
};
var MessageWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var TextWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: 16px 16px 16px 2px;\n  background-color: #f3f3f3;\n  padding: 8px;\n"], ["\n  border-radius: 16px 16px 16px 2px;\n  background-color: #f3f3f3;\n  padding: 8px;\n"])));
var ButtonsWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"])));
var StyledButton = styled(Button)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  border-radius: 2px 16px 16px 16px !important;\n"], ["\n  border-radius: 2px 16px 16px 16px !important;\n"])));
var ItemsListContent = function (_a) {
    var message = _a.message;
    var _b = React.useContext(LiveChatContext), currentSession = _b.currentSession, realtimeConnection = _b.realtimeConnection;
    var _c = React.useContext(LiveChatMutationsContext), createMessage = _c.createMessage, sendMessageToAPI = _c.sendMessageToAPI;
    var _d = __read(React.useState(false), 2), isListOpen = _d[0], setIsListOpen = _d[1];
    var _e = __read(React.useState(false), 2), wasSelected = _e[0], setWasSelected = _e[1];
    var hasTransitionedIn = useMountTransition(isListOpen, 250);
    var handleSend = function (message, extraData) {
        var newMessage = createMessage(message, extraData);
        sendMessageToAPI(newMessage).then(function () { return setWasSelected(true); });
    };
    var disabled = !realtimeConnection.isConnected || currentSession.isCompleted || wasSelected;
    return (React.createElement(MessageWrapper, null,
        React.createElement(TextWrapper, null,
            React.createElement(TextInnerContent, { message: message })),
        React.createElement(ButtonsWrapper, null,
            React.createElement(StyledButton, { variant: BUTTON_VARIANT, color: "positive", onClick: function () { return setIsListOpen(true); }, leftIcon: React.createElement(Icon, { type: "clipboard-list", "aria-hidden": true }), disabled: disabled }, message.interactive_content.interactive.action.button)),
        (isListOpen || hasTransitionedIn) && (React.createElement(ItemsListDialog, { message: message, onSend: handleSend, hasTransitionedIn: hasTransitionedIn, close: function () { return setIsListOpen(false); } }))));
};
export default ItemsListContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
