var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { isInteractive, isItemsListMessage, isInteractiveButtonsMessage, } from '../../../sharedInterfaces';
import { useI18n } from '../../../commons/useI18n';
import AnnexInnerContent from './AnnexContent';
import TextInnerContent from './TextContent';
import InteractiveButtonsContent from './InteractiveButtonsContent';
import ItemsListContent from './ItemsListContent';
var MessageContentElement = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: inline-flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  white-space: pre-wrap;\n  gap: 8px;\n  width: 100%;\n\n  p:first-child {\n    margin-top: 0;\n  }\n\n  p:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  align-items: center;\n  display: inline-flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  white-space: pre-wrap;\n  gap: 8px;\n  width: 100%;\n\n  p:first-child {\n    margin-top: 0;\n  }\n\n  p:last-child {\n    margin-bottom: 0;\n  }\n"])));
var InteractiveComponent = function (_a) {
    var message = _a.message;
    if (isInteractiveButtonsMessage(message)) {
        return React.createElement(InteractiveButtonsContent, { message: message });
    }
    if (isItemsListMessage(message)) {
        return React.createElement(ItemsListContent, { message: message });
    }
    return null;
};
function ContentComponent(_a) {
    var _b;
    var message = _a.message;
    if ((_b = message.annex) === null || _b === void 0 ? void 0 : _b.uuid)
        return React.createElement(AnnexInnerContent, { message: message });
    if (isInteractive(message))
        return React.createElement(InteractiveComponent, { message: message });
    return React.createElement(TextInnerContent, { message: message });
}
function MessageContent(_a) {
    var message = _a.message;
    var i18n = useI18n();
    return (React.createElement(MessageContentElement, { "aria-label": i18n._('Message content') },
        React.createElement(ContentComponent, { message: message })));
}
export default MessageContent;
var templateObject_1;
