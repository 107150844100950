var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Text } from 'evolux-ui-v2';
import styled from 'styled-components';
import { useI18n } from '../../commons/useI18n';
var InputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 8px 0 4px;\n  height: 32px;\n  display: flex;\n  justify-items: center;\n\n  &:focus {\n    border: 1px solid var(--primary);\n  }\n\n  & > div {\n    width: 100%;\n\n    input {\n      box-shadow: ", ";\n      color: #222;\n      flex: 100 1 0;\n      font-weight: 400;\n      line-height: 32px;\n      border-radius: 2px;\n      border: none;\n    }\n  }\n\n  & .PhoneInput {\n    & > input {\n      &:focus {\n        box-shadow: 0 0 0 2px var(--primary);\n        outline: none;\n      }\n    }\n  }\n"], ["\n  margin: 8px 0 4px;\n  height: 32px;\n  display: flex;\n  justify-items: center;\n\n  &:focus {\n    border: 1px solid var(--primary);\n  }\n\n  & > div {\n    width: 100%;\n\n    input {\n      box-shadow: ", ";\n      color: #222;\n      flex: 100 1 0;\n      font-weight: 400;\n      line-height: 32px;\n      border-radius: 2px;\n      border: none;\n    }\n  }\n\n  & .PhoneInput {\n    & > input {\n      &:focus {\n        box-shadow: 0 0 0 2px var(--primary);\n        outline: none;\n      }\n    }\n  }\n"])), function (props) { return (props.hasError ? '0 0 0 1px var(--negative)' : '0 0 0 1px #999'); });
var PhoneNumberInput = function (_a) {
    var label = _a.label, value = _a.value, onPhoneChange = _a.onPhoneChange, hasErrors = _a.hasErrors, required = _a.required;
    var i18n = useI18n();
    return (React.createElement("div", null,
        React.createElement("label", { htmlFor: "".concat(label, "-phone-input") },
            React.createElement(Text, { size: "sm", bold: true }, label)),
        React.createElement(InputWrapper, { hasError: hasErrors },
            React.createElement(PhoneInput, { id: "".concat(label, "-phone-input"), placeholder: i18n._('Phone number'), defaultCountry: 'BR', initialValueFormat: 'national', limitMaxLength: true, countryOptionsOrder: ['BR', 'US', 'CA'], displayInitialValueAsLocalNumber: true, value: value, onChange: onPhoneChange, required: required }))));
};
export default PhoneNumberInput;
var templateObject_1;
