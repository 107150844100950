var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../../commons/useI18n';
import CurrentSessionInput from './CurrentSessionInput';
import ChatMessageList from './CurrentSessionMessageList';
import CurrentSessionState from './CurrentSessionState';
var CurrentSessionElement = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  height: 500px;\n  padding: 8px 16px;\n  width: 100%;\n"], ["\n  align-items: center;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  height: 500px;\n  padding: 8px 16px;\n  width: 100%;\n"])));
function CurrentSession() {
    var i18n = useI18n();
    var _a = __read(React.useState(), 2), messageBeingReplied = _a[0], setMessageBeingReplied = _a[1];
    return (React.createElement(CurrentSessionElement, { "aria-live": "polite", "aria-label": i18n._('Current session') },
        React.createElement(ChatMessageList, { setMessageBeingReplied: setMessageBeingReplied }),
        React.createElement(CurrentSessionState, null),
        React.createElement(CurrentSessionInput, { messageBeingReplied: messageBeingReplied, setMessageBeingReplied: setMessageBeingReplied })));
}
export default CurrentSession;
var templateObject_1;
