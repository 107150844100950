import React from 'react';
import { Text } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import { LiveChatContext, LiveChatMutationsContext, } from '../../contextProviders/LiveChatContextProvider';
import { v4 as uuid } from 'uuid';
var i18n = useI18n();
function CurrentSessionState() {
    var _a;
    var _b = React.useContext(LiveChatContext), currentSession = _b.currentSession, fields = _b.fields, shouldShowIceBreakers = _b.shouldShowIceBreakers, channelIceBreakers = _b.channelIceBreakers;
    var _c = React.useContext(LiveChatMutationsContext), resetWidget = _c.resetWidget, createNewSession = _c.createNewSession;
    var innerText = React.useMemo(function () {
        var _a;
        switch (currentSession.state) {
            case 'waiting':
                return i18n._('Waiting for an agent to answer');
            case 'answered':
                return i18n._('The agent %s is chatting with you', (_a = currentSession.user) === null || _a === void 0 ? void 0 : _a.name);
            case 'survey':
                return i18n._('Waiting survey answer');
            case 'menu':
                return i18n._('Menu');
            case 'bot':
                return i18n._('Virtual agent');
        }
        return i18n._('Unknown session state');
    }, [currentSession.state, (_a = currentSession.user) === null || _a === void 0 ? void 0 : _a.name]);
    function handleStartANewSession(event) {
        var newConversationUuid = uuid();
        event.preventDefault();
        resetWidget(newConversationUuid, fields);
        if (fields && !fields.length && !channelIceBreakers) {
            createNewSession({}, newConversationUuid);
        }
    }
    if (shouldShowIceBreakers) {
        return React.createElement("div", null);
    }
    return (React.createElement("span", { "aria-label": i18n._('Current session state') }, currentSession.isCompleted ? (React.createElement(React.Fragment, null,
        React.createElement(Text, { transparent: true }, i18n._('This session was closed. ')),
        React.createElement(Text, { type: "link", href: "#", onClick: handleStartANewSession }, i18n._('Start a new session')))) : (React.createElement(Text, { transparent: true }, innerText))));
}
export default CurrentSessionState;
