var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DateTime } from 'luxon';
import JsFileDownloader from 'js-file-downloader';
export var buildDisplayTime = function (when) {
    if (when.isValid) {
        return when.toLocaleString(DateTime.TIME_24_SIMPLE);
    }
    return '';
};
export var downloadFile = function (url, filename) {
    return new JsFileDownloader({ url: url, filename: filename });
};
export var convertLabelToName = function (label) {
    return label.trim().toLowerCase().replace(/ /g, '_');
};
export var standardizeFields = function (fields) {
    return fields.map(function (field) {
        return field.name
            ? __assign(__assign({}, field), { name: field.name, error: '' }) : __assign(__assign({}, field), { name: convertLabelToName(field.label), isCustom: true, error: '' });
    });
};
