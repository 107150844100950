var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Text, TooltipContainer } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
var Preview = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  padding: 0;\n  text-align: center;\n  width: 50%;\n\n  .ev2-tooltipcontainer {\n    width: 100%;\n  }\n"], ["\n  flex-grow: 1;\n  padding: 0;\n  text-align: center;\n  width: 50%;\n\n  .ev2-tooltipcontainer {\n    width: 100%;\n  }\n"])));
var AttachmentPreview = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n"], ["\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n"])));
var Img = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n"], ["\n  width: 32px;\n  height: 32px;\n"])));
var FileName = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  margin-left: 6px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n"], ["\n  display: block;\n  margin-left: 6px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n"])));
var FilePreview = function (_a) {
    var file = _a.file, onRemove = _a.onRemove;
    var i18n = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: onRemove, "aria-label": i18n._('Remove file %s', file.name), variant: "tertiary", shape: "square" },
            React.createElement(Icon, { type: "trash", color: "negative", "aria-hidden": true })),
        React.createElement(Preview, null,
            React.createElement(TooltipContainer, { message: file.name },
                React.createElement(AttachmentPreview, null,
                    React.createElement(Img, { src: URL.createObjectURL(file), alt: "" }),
                    React.createElement(FileName, { size: "sm", transparent: true, "aria-hidden": true }, file.name))))));
};
export default FilePreview;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
