var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
import React from 'react';
import { Text } from 'evolux-ui-v2';
import styled from 'styled-components';
import TookeLogo from '../../svgIcons/tooke.svg';
// @ts-ignore
var IMG_URL = window.LIVECHAT_LOGO_ICON;
// @ts-ignore
var IMG_WIDTH = (_a = window.LIVECHAT_HEADER_LOGO_WIDTH) !== null && _a !== void 0 ? _a : 28;
// @ts-ignore
var IMG_HEIGHT = (_b = window.LIVECHAT_HEADER_LOGO_HEIGHT) !== null && _b !== void 0 ? _b : 28;
// @ts-ignore
var TITLE = window.LIVECHAT_TITLE;
var HeaderElement = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n\n  .header__icon {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n  }\n\n  .header__actions {\n    display: inline-flex;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n\n  .header__icon {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n  }\n\n  .header__actions {\n    display: inline-flex;\n    align-items: center;\n  }\n"])));
function Header(_a) {
    var actions = _a.actions;
    return (React.createElement(HeaderElement, null,
        React.createElement("div", { className: "header__icon", "aria-hidden": true }, IMG_URL ? (React.createElement("img", { alt: "", src: IMG_URL, width: IMG_WIDTH, height: IMG_HEIGHT, "aria-hidden": true })) : (React.createElement(TookeLogo, { width: IMG_WIDTH, height: IMG_HEIGHT, "aria-hidden": true }))),
        React.createElement(Text, { as: "h1", size: "lg", type: "title" }, TITLE !== null && TITLE !== void 0 ? TITLE : 'Live Chat'),
        actions && React.createElement("div", { className: "header__actions" }, actions)));
}
export default Header;
var templateObject_1;
