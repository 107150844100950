var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Button, Input, Text, TextArea, Hint } from 'evolux-ui-v2';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import find from 'lodash/find';
import { moveMessageToLastPosition } from './utils';
import { useI18n } from '../../commons/useI18n';
import { LiveChatMutationsContext, LiveChatContext, } from '../../contextProviders/LiveChatContextProvider';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CPFCNPJInput from './CPFCNPJInput';
var FormElement = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding-top: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding-top: 16px;\n"])));
var StyledHint = styled(Hint)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: -12px;\n\n  & > span {\n    font-size: 12px !important;\n  }\n"], ["\n  margin-top: -12px;\n\n  & > span {\n    font-size: 12px !important;\n  }\n"])));
var FieldComponent = function (_a) {
    var _b;
    var field = _a.field, value = _a.value, formik = _a.formik, errorProcedingToSession = _a.errorProcedingToSession, setErrorProcedingToSession = _a.setErrorProcedingToSession;
    var i18n = useI18n();
    var handleTextChange = function (event) {
        if (errorProcedingToSession !== '') {
            setErrorProcedingToSession('');
        }
        formik.handleChange(event);
    };
    var commonProps = {
        label: field.required ? "".concat(i18n._(field.label), " *") : "".concat(i18n._(field.label)),
        name: field.name,
        required: field.required,
        onBlur: formik.handleBlur,
        'data-lpignore': true,
    };
    var errorMessage = formik.touched[field.name] ? formik.errors[field.name] : '';
    if (field.type === 'textarea') {
        return (React.createElement(TextArea, __assign({ value: value !== null && value !== void 0 ? value : '', onChange: handleTextChange, errorHint: errorMessage, maxLength: field.max_length }, commonProps)));
    }
    if (field.type === 'CPF/CNPJ') {
        return (React.createElement(CPFCNPJInput, __assign({ onChange: function (value) { return formik.setFieldValue(field.name, value); }, errorHint: errorMessage }, commonProps)));
    }
    if (field.name === 'phone') {
        var phoneHasErrors = !!value && !!formik.touched[field.name] && !isValidPhoneNumber(value);
        return (React.createElement(React.Fragment, null,
            React.createElement(PhoneNumberInput, __assign({ value: value !== null && value !== void 0 ? value : '', onPhoneChange: function (value) {
                    formik.setFieldValue(field.name, value);
                    if (errorProcedingToSession !== '') {
                        setErrorProcedingToSession('');
                    }
                }, hasErrors: phoneHasErrors }, commonProps)),
            phoneHasErrors && React.createElement(StyledHint, { text: (_b = formik.errors[field.name]) !== null && _b !== void 0 ? _b : '', color: 'negative' })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, __assign({ value: value !== null && value !== void 0 ? value : '', onChange: handleTextChange, type: field.type, errorHint: errorMessage, maxLength: field.max_length }, commonProps)),
        field.type === 'number' && (React.createElement(Text, { size: "sm", italic: true, transparent: true }, i18n._('Only numbers allowed')))));
};
function NewSessionForm(_a) {
    var fields = _a.fields, onCreationSuccess = _a.onCreationSuccess;
    var i18n = useI18n();
    var _b = __read(React.useState(''), 2), errorProcedingToSession = _b[0], setErrorProcedingToSession = _b[1];
    var _c = React.useContext(LiveChatMutationsContext), createNewSession = _c.createNewSession, updateSession = _c.updateSession, updateIceBreakers = _c.updateIceBreakers;
    var shouldShowIceBreakers = React.useContext(LiveChatContext).shouldShowIceBreakers;
    var orderedFields = moveMessageToLastPosition(fields);
    var customFields = filter(orderedFields, function (field) {
        return !!field.isCustom;
    }).map(function (field) { return field.name; });
    var initialValues = orderedFields.reduce(function (acc, field) {
        var _a;
        return __assign(__assign({}, acc), (_a = {}, _a[field.name] = '', _a));
    }, {});
    var formHasMessageField = !!find(fields, function (field) { return field.name === 'message'; });
    var shouldRenderIceBreakers = shouldShowIceBreakers && !formHasMessageField;
    function handleFormSubmit(values) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var payload, error_1, message, status_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        setErrorProcedingToSession(function (prevState) { return (prevState === '' ? '' : ' '); });
                        payload = customFields.length === 0
                            ? __assign({}, omit(values, customFields)) : __assign(__assign({}, omit(values, customFields)), { custom_fields: pick(values, customFields) });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 6]);
                        if (!shouldRenderIceBreakers) return [3 /*break*/, 2];
                        updateSession({ formFields: payload });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createNewSession(payload)];
                    case 3:
                        _d.sent();
                        updateIceBreakers(undefined);
                        _d.label = 4;
                    case 4:
                        if (onCreationSuccess) {
                            onCreationSuccess();
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _d.sent();
                        message = (_b = (_a = error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_message;
                        if (message) {
                            setErrorProcedingToSession(message);
                        }
                        else {
                            status_1 = (_c = error_1.response) === null || _c === void 0 ? void 0 : _c.status;
                            switch (status_1) {
                                case 400:
                                    setErrorProcedingToSession(i18n._('Missing parameters'));
                                    break;
                                case 403:
                                    setErrorProcedingToSession(i18n._('Invalid credentials'));
                                    break;
                                default:
                                    setErrorProcedingToSession(i18n._('Failed to proceed to session'));
                            }
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    var getFieldsValidation = function (fields) {
        return fields.reduce(function (acc, field) {
            var _a, _b, _c;
            if (field.name === 'phone') {
                return __assign(__assign({}, acc), (_a = {}, _a[field.name] = Yup.string().test('is-valid', i18n._('Invalid phone number'), function (value) { return !value || isValidPhoneNumber(value); }), _a));
            }
            if (field.type === 'CPF/CNPJ') {
                return __assign(__assign({}, acc), (_b = {}, _b[field.name] = Yup.string().test('is-valid', i18n._('Invalid value'), function (value) {
                    if (value && (cpf.isValid(value) || cnpj.isValid(value))) {
                        return true;
                    }
                    return false;
                }), _b));
            }
            if (field.validator) {
                return __assign(__assign({}, acc), (_c = {}, _c[field.name] = Yup.string().matches(new RegExp(field.validator), i18n._('Invalid value')), _c));
            }
            return acc;
        }, {});
    };
    var formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape(getFieldsValidation(orderedFields)),
        onSubmit: handleFormSubmit,
        enableReinitialize: true,
        validateOnChange: true,
    });
    return (React.createElement(FormElement, { onSubmit: formik.handleSubmit, "aria-label": i18n._('User identification'), autoComplete: "off" }, orderedFields === null || orderedFields === void 0 ? void 0 :
        orderedFields.map(function (field) {
            return (React.createElement(FieldComponent, { key: field.name, field: field, value: formik.values[field.name], formik: formik, errorProcedingToSession: errorProcedingToSession, setErrorProcedingToSession: setErrorProcedingToSession }));
        }),
        React.createElement(Text, { size: "sm", transparent: true }, i18n._('Inputs marked with * are required.')),
        errorProcedingToSession && React.createElement(Hint, { color: "negative", text: errorProcedingToSession }),
        React.createElement(Button, { color: "positive", type: "submit", disabled: formik.isSubmitting }, i18n._('Procceed to session'))));
}
export default NewSessionForm;
var templateObject_1, templateObject_2;
