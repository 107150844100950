import React from 'react';
import { Socket } from 'phoenix';
import { DateTime } from 'luxon';
var MAX_TIME_RANGE_MS = 4000;
function getSocketUrl() {
    var realtimeUrl = window.TOOKE_REALTIME_PUBLIC_URL ||
        process.env.TOOKE_REALTIME_PUBLIC_URL ||
        window.TOOKE_API_PUBLIC_URL ||
        process.env.TOOKE_API_PUBLIC_URL;
    if (realtimeUrl && realtimeUrl.startsWith('http')) {
        return realtimeUrl.replace(/^http/, 'ws') + '/socket';
    }
    return "".concat(realtimeUrl, "/socket");
}
export var handleConnectionTimeout = function (onTimeout, wsSocket, unsubscribeFromChannnels, initialTimestampMs) {
    var currentMs = DateTime.now();
    var timeDiffFromStartMs = currentMs.diff(initialTimestampMs).toMillis();
    if (timeDiffFromStartMs >= MAX_TIME_RANGE_MS) {
        wsSocket.disconnect(function () {
            unsubscribeFromChannnels();
            onTimeout();
        });
    }
};
export function useSocketConnection(realtimeConnection, setRealtimeConnection, performSubscribeToChannels, shouldFetchMessages, setShouldFetchMessages, retrieveConversationMessages) {
    var socketConnection = React.useRef(new Socket(getSocketUrl()));
    var socketChannel = React.useRef();
    var firstConnectionTimestampMs = React.useRef(DateTime.now());
    var isConnected = realtimeConnection.isConnected, isTryingToConnect = realtimeConnection.isTryingToConnect, hasReachedTimeout = realtimeConnection.hasReachedTimeout;
    var onTimeout = function () {
        setRealtimeConnection({
            isConnected: false,
            isTryingToConnect: false,
            hasReachedTimeout: true,
        });
    };
    React.useEffect(function () {
        socketConnection.current.onOpen(function () {
            if (shouldFetchMessages.current) {
                retrieveConversationMessages().then(function () {
                    setShouldFetchMessages(false);
                });
            }
        });
        socketConnection.current.onClose(function () {
            setShouldFetchMessages(true);
            handleConnectionTimeout(onTimeout, socketConnection.current, unsubscribeFromChannnels, firstConnectionTimestampMs.current);
        });
        socketConnection.current.onError(function () {
            handleConnectionTimeout(onTimeout, socketConnection.current, unsubscribeFromChannnels, firstConnectionTimestampMs.current);
        });
        return function () {
            var _a;
            unsubscribeFromChannnels();
            if (((_a = socketConnection.current) === null || _a === void 0 ? void 0 : _a.connectionState()) === 'open') {
                socketConnection.current.disconnect();
            }
        };
    }, []);
    React.useEffect(function () {
        if (!isConnected && !isTryingToConnect && !hasReachedTimeout) {
            socketConnection.current.connect();
            performSubscribeToChannels();
            firstConnectionTimestampMs.current = DateTime.now();
            setRealtimeConnection({
                isConnected: false,
                isTryingToConnect: true,
                hasReachedTimeout: false,
            });
        }
    }, [isConnected, isTryingToConnect, hasReachedTimeout]);
    function subscribeToChannels(channelUUID, _a) {
        var onNewMessage = _a.onNewMessage, onSessionUpdate = _a.onSessionUpdate, onConnectionSuccessful = _a.onConnectionSuccessful;
        socketChannel.current = socketConnection.current.channel(channelUUID);
        socketChannel.current.join().receive('ok', function () {
            var _a, _b;
            onConnectionSuccessful();
            (_a = socketChannel.current) === null || _a === void 0 ? void 0 : _a.on('new-message', function (_a) {
                var data = _a.data;
                onNewMessage(data);
            });
            (_b = socketChannel.current) === null || _b === void 0 ? void 0 : _b.on('update-conversation', function (_a) {
                var data = _a.data;
                onSessionUpdate(data);
            });
        });
    }
    function unsubscribeFromChannnels() {
        var _a;
        (_a = socketChannel.current) === null || _a === void 0 ? void 0 : _a.leave();
    }
    return { subscribeToChannels: subscribeToChannels, unsubscribeFromChannnels: unsubscribeFromChannnels };
}
