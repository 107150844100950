var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Spinner, TooltipContainer } from 'evolux-ui-v2';
import { DateTime } from 'luxon';
import find from 'lodash/find';
import { useI18n } from '../../commons/useI18n';
import MessageComponent from '../MessageComponent/MessageComponent';
import { LiveChatContext } from '../../contextProviders/LiveChatContextProvider';
// @ts-ignore
var backgroundURL = window.LIVECHAT_BACKGROUND_URL;
// @ts-ignore
var backgroundMessagesOnly = window.LIVECHAT_BACKGROUND_MESSAGES_ONLY;
var StyledTooltipContainer = styled(TooltipContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-self: center;\n  padding: 10px 0;\n"], ["\n  align-self: center;\n  padding: 10px 0;\n"])));
var MessagesWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n\n  width: 100%;\n  padding: 0 5px;\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n  gap: 8px;\n  overflow-y: auto;\n  scroll-behavior: smooth;\n\n  & > :first-child {\n    margin-top: auto;\n  }\n\n  &::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #999999;\n    border-radius: 8px;\n    width: 16px;\n    border: 4px solid white;\n  }\n\n  &::-webkit-scrollbar-track {\n    opacity: 0;\n  }\n"], ["\n  ", "\n\n  width: 100%;\n  padding: 0 5px;\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n  gap: 8px;\n  overflow-y: auto;\n  scroll-behavior: smooth;\n\n  & > :first-child {\n    margin-top: auto;\n  }\n\n  &::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #999999;\n    border-radius: 8px;\n    width: 16px;\n    border: 4px solid white;\n  }\n\n  &::-webkit-scrollbar-track {\n    opacity: 0;\n  }\n"])), backgroundMessagesOnly &&
    "\n  background: no-repeat url(".concat(backgroundURL, ");\n  background-size: cover;\n  border-radius: 5px;\n"));
var convertIceBreakersToMessage = function (iceBreakers) {
    var _a;
    return ({
        when: (_a = DateTime.now().toISO()) !== null && _a !== void 0 ? _a : undefined,
        author: 'SYSTEM',
        message: iceBreakers.initial_message,
        uuid: 'iceBreakerUuid',
        interactive_content: {
            type: 'interactive',
            interactive: {
                action: {
                    buttons: iceBreakers.options.map(function (option) {
                        return {
                            reply: {
                                id: option.uuid,
                                title: option.text,
                            },
                            type: 'reply',
                        };
                    }),
                },
                body: {
                    text: iceBreakers.initial_message,
                },
                type: 'iceBreakers',
            },
        },
    });
};
function scrollMessageListToEnd(messageList) {
    if (messageList)
        messageList.scrollTop = messageList.scrollHeight;
}
function CurrentSessionMessageList(_a) {
    var setMessageBeingReplied = _a.setMessageBeingReplied;
    var _b = React.useContext(LiveChatContext), messages = _b.messages, isRetrievingMessages = _b.isRetrievingMessages, iceBreakers = _b.iceBreakers, shouldShowIceBreakers = _b.shouldShowIceBreakers, fields = _b.fields;
    var messageListRef = React.useRef(null);
    var i18n = useI18n();
    setTimeout(function () { return scrollMessageListToEnd(messageListRef.current); }, 0);
    var iceBreakersMessage = iceBreakers && convertIceBreakersToMessage(iceBreakers);
    var formHasMessageField = !!find(fields, function (field) { return field.name === 'message'; });
    var hasInitialForm = fields && fields.length > 0;
    var shouldRenderIceBreakersMessage = shouldShowIceBreakers && (!hasInitialForm || !formHasMessageField);
    return (React.createElement(MessagesWrapper, { className: "current-session__messages-wrapper", ref: messageListRef },
        shouldRenderIceBreakersMessage ? (React.createElement(MessageComponent, { message: iceBreakersMessage, setMessageBeingReplied: setMessageBeingReplied })) : (messages.map(function (message) { return (React.createElement(MessageComponent, { key: message.uuid, message: message, setMessageBeingReplied: setMessageBeingReplied })); })),
        isRetrievingMessages && (React.createElement(StyledTooltipContainer, { message: i18n._('Loading new messages') },
            React.createElement(Spinner, { size: 3 })))));
}
export default CurrentSessionMessageList;
var templateObject_1, templateObject_2;
