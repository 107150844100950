// inspired by https://github.com/samhirtarif/react-audio-recorder/blob/76769f22d5d8c71acc1824f2f5125c80eaa90bc8/src/hooks/useAudioRecorder.ts
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
import vmsg from 'vmsg';
// @ts-ignore
var recorder = new vmsg.Recorder({
    wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});
var useRecorder = function () {
    var _a = __read(useState(false), 2), isRecording = _a[0], setIsRecording = _a[1];
    var _b = __read(useState(), 2), recordedData = _b[0], setRecordedData = _b[1];
    var _c = __read(useState(0), 2), recordingTime = _c[0], setRecordingTime = _c[1];
    var _d = __read(useState(), 2), timerInterval = _d[0], setTimerInterval = _d[1];
    var startTimer = function () {
        var interval = setInterval(function () {
            setRecordingTime(function (time) { return time + 1; });
        }, 1000);
        setTimerInterval(interval);
    };
    var stopTimer = function () {
        timerInterval != null && clearInterval(timerInterval);
        setRecordingTime(0);
        setTimerInterval(undefined);
    };
    var startRecording = function () {
        return recorder
            .initAudio()
            .then(function () { return recorder.initWorker(); })
            .then(function () {
            recorder.startRecording();
            startTimer();
            setIsRecording(true);
        });
    };
    var stopRecording = function () {
        if (isRecording) {
            return recorder.stopRecording().then(function (blob) {
                stopTimer();
                setIsRecording(false);
                setRecordedData(blob);
            });
        }
        return Promise.resolve();
    };
    var discardRecordedData = function () {
        setRecordedData(undefined);
    };
    return {
        isRecording: isRecording,
        startRecording: startRecording,
        stopRecording: stopRecording,
        discardRecordedData: discardRecordedData,
        recordedData: recordedData,
        recordingTime: recordingTime,
    };
};
export default useRecorder;
