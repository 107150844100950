var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import qs from 'qs';
function getliveChatChannelUUID() {
    var channelUUID = window.LIVECHAT_CHANNEL_UUID || process.env.LIVECHAT_CHANNEL_UUID;
    if (!channelUUID) {
        console.error('Tooke Live Chat: Could not perform correctly, please set the LIVECHAT_CHANNEL_UUID global variable and try again.');
        return '';
    }
    return channelUUID;
}
function buildBaseURL() {
    var baseURL = window.TOOKE_API_PUBLIC_URL || process.env.TOOKE_API_PUBLIC_URL;
    return "".concat(baseURL, "/api/v1/livechat/").concat(getliveChatChannelUUID());
}
export var client = axios.create({
    baseURL: buildBaseURL(),
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});
var formatInfo = function (info) {
    if (info.custom_fields) {
        return __assign(__assign({}, info), { custom_fields: JSON.stringify(info.custom_fields) });
    }
    else {
        return info;
    }
};
export function createLiveChatSession(newSessionInfo) {
    return client.post('', formatInfo(newSessionInfo));
}
export function sendNewMessage(message, messageUUID, sessionUUID, annex, replyToUuid) {
    var url = "/session/".concat(sessionUUID, "/messages");
    if (annex && annex.file) {
        var formData = new FormData();
        formData.append('message', message);
        formData.append('message_uuid', messageUUID);
        formData.append(annex.original_filename, annex.file);
        if (replyToUuid) {
            formData.append('reply_to_uuid', replyToUuid);
        }
        return client({
            method: 'post',
            url: url,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }
    return client.post(url, __assign({ message: message, message_uuid: messageUUID }, (replyToUuid ? { reply_to_uuid: replyToUuid } : {})));
}
export var getLivechatChannelData = function () {
    return client.get('');
};
export function fetchMessages(sessionUUID) {
    var url = "/session/".concat(sessionUUID, "/messages");
    return client.get(url);
}
export function setMessagesAsRead(sessionUUID, messagesUUIDs) {
    var url = "/session/".concat(sessionUUID, "/messages");
    return client.patch(url, qs.stringify({ messages_uuids: messagesUUIDs }, { indices: false }));
}
